import "./carousel.scss";

const indicator = document.getElementById('js--carouselIndicators');
const thumbnails = document.getElementsByClassName('thumbnail')[0];
const mobileCarousel = document.getElementsByClassName('mobile-carousel')[0];

if (indicator !== null && indicator !== undefined) {
	const carouselTrigger = document.getElementsByClassName('carousel-control-next')[0];
	carouselTrigger.addEventListener('click',  () => {
		setIndicator();
	});
}

function setIndicator() {
	const carouselLength = Array.from(mobileCarousel.getElementsByClassName('carousel-item')).length;
	let currentSlideIndex = mobileCarousel.getElementsByClassName('carousel-item active')[0].dataset.index;
	let nextSlideIndex = parseInt(currentSlideIndex) + 1;

	if (nextSlideIndex > carouselLength) {
		nextSlideIndex = 1;
	}
	indicator.innerHTML = `${nextSlideIndex}/${carouselLength}`
}

if (thumbnails !== null && thumbnails !== undefined) {
	$('#desktop-carousel').on('slide.bs.carousel', function (event) {
		unactivateThumbnails();
		setCurrentThumbnail(event.to);
	});
	const slideTriggers = Array.from(document.getElementsByClassName('slide_to_thumbnail'));
	slideTriggers.forEach(element => {
		element.addEventListener('click', (event) => {
			setTimeout(function () {
				document.getElementById(`js--item${event.target.dataset.bsSlideTo}`).click();
			}, 400);
		});
	});
}

function setCurrentThumbnail(index) {
	const thumbnail = document.getElementById(`js--item${index}`);
	thumbnail.classList.add('active');
	thumbnail.parentNode.scrollLeft = thumbnail.offsetLeft;
}

function unactivateThumbnails() {
	const activeThumbnails = Array.from(document.getElementsByClassName('thumbnail active'));
	activeThumbnails.forEach(element => {
		element.classList.remove('active');
	});
}

