import './reviews.scss'

function unactivateDots() {
  const active_dots = document.getElementsByClassName('review-dot active');
  Array.prototype.forEach.call(active_dots, function(dot) {
    dot.classList.remove('active');
  })
}

function setActiveDot(quoteIndex) {
  unactivateDots();
  document.getElementById(`js--reviews-${quoteIndex}-dot`).classList.add('active');
}

function slideQuotes(quoteIndex) {
  let margin = 0;
  if (quoteIndex == 1) {
    margin = '0px';
  } else {
    margin = `${(quoteIndex - 1) * -900}px`;
  }
  document.getElementById('js--reviews').style.marginLeft = margin;
}

function delayTime(quoteIndex) {
  return document.getElementById(`js--reviews-${quoteIndex}`).dataset.readingtime;
}

function startSlideShow(quoteIndex) {
  const maxQuoteIndex = document.querySelectorAll('.review').length;
  if (stopSlideShow == false) {
    slideQuotes(quoteIndex);
    setActiveDot(quoteIndex);
    setTimeout(function () {
      if (quoteIndex == maxQuoteIndex) {
        startSlideShow(1);
      } else {
        startSlideShow(quoteIndex + 1);
      }
    }, delayTime(quoteIndex));
  }
}

let stopSlideShow = false;
let quoteIndex = 1;
const firstReviewsDot = document.getElementById('js--reviews-1-dot');

if (firstReviewsDot !== null && firstReviewsDot !== undefined) {
  startSlideShow(quoteIndex);
  Array.prototype.forEach.call(document.getElementsByClassName('review-dot'), function (dot) {
    dot.addEventListener('click', (event) => {
      stopSlideShow = true;
      quoteIndex = event.target.id.charAt(event.target.id.length - 5);
      setActiveDot(quoteIndex)
      slideQuotes(quoteIndex);
    })
  })
}
