document.addEventListener("change", function selectClicked(event) {
  if (event.target.classList.contains("click_link_on_change")) {
    const link = `link_to_${
      event.target.options[event.target.options.selectedIndex].id
    }`;
    document.getElementById(link).click();
  }
});

document.addEventListener("click", function selectClicked(event) {
  if (event.target.classList.contains("resetButton")) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
});

