const overlappingContainers = Array.from(document.getElementsByClassName('overlapping_container'));

if (overlappingContainers.length > 0) {
  overlappingContainers.forEach(container => {
    container.style.transition = 'none';
    container.style.marginTop = '0';
    setTimeout(function () {
      container.style.marginTop = '-150px';
      container.style.transition = '900ms';
    }, 200);
  });
}
