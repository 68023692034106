import $ from "jquery";

import "bootstrap";

import "../stylesheets/application";

import "../components/navbar/navbar";

import "../components/reviews/reviews";

import "../components/schluter_systems/schluter_systems";

import "../components/cookiebar/cookiebar";

import "../components/carousel/carousel";

import "../components/footer/footer";

import "../scripts/eventListeners";

import "../scripts/scroll_fade_in";

require("@rails/ujs").start();

window.jQuery = $;

window.$ = $;
