import './cookiebar.scss';

function set_cookies() {
  let acceptedCookies = localStorage.getItem('airbase_cookies');

  if (acceptedCookies == null) {
    show_cookiebar();
  }
  if (acceptedCookies == 'personal') {
    show_youtube_videos();
    hide_youtube_placeholders();
  }
}

function show_youtube_videos() {
  const videos = Array.from(
    document.getElementsByClassName("iframe")
  );
  if (videos.length > 0) {
    videos.forEach(video => {
      video.src = video.dataset.src;
      video.classList.remove('d-none');
    });
  }
}

function hide_youtube_placeholders() {
  const placeholders = Array.from(
    document.getElementsByClassName("iframe-placeholder")
  );
  if (placeholders.length > 0) {
    placeholders.forEach(placeholder => {
      placeholder.classList.add('d-none');
    });
  }
}

function show_cookiebar() {
  const cookiebar = document.getElementById('cookiebar');
  if (cookiebar !== null && cookiebar !== undefined) {
    document.getElementById('cookiebar').classList.remove('d-none');
  }
}

function hide_cookiebar() {
  const cookiebar = document.getElementById('cookiebar');
  if (cookiebar !== null && cookiebar !== undefined) {
    document.getElementById('cookiebar').classList.add('d-none');
  }
}

function accepted_personal_cookies() {
  localStorage.setItem('airbase_cookies', 'personal');
  show_youtube_videos();
  hide_youtube_placeholders();
}

function show_submit_text() {
  const acceptedCookiesNotification = document.getElementById('accept_cookies_submit');
  if (acceptedCookiesNotification !== null && acceptedCookiesNotification !== undefined) {
    acceptedCookiesNotification.classList.remove('d-none');
  }
}

document.addEventListener('click', event => {
  if (event.target.className.includes('accept_cookies')) {
    hide_cookiebar();
    if (document.querySelector('input[name="cookies"]:checked') != null) {
      const selected_option = document.querySelector('input[name="cookies"]:checked').value;
      if (selected_option == 'standard') {
        localStorage.setItem('airbase_cookies', 'standard');
      } else {
        accepted_personal_cookies();
      }
    } else {
      accepted_personal_cookies();
    }
    show_submit_text();
  }
});

window.addEventListener("load", function () {
  set_cookies();
});
